/* Navbar Styles */

  .navbar-brand {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .navbar-brand img {
    margin-right: 10px; /* Space between the logo and text */
  }
  
  .navbar-toggler {
    border: none;
  }
  
  .navbar-toggler-icon {
    width: 24px;
    height: 24px;
  }
  
  .navbar-nav {
    margin-left: auto;
  }
  
  .nav-item .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .nav-item .nav-link:hover {
    color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 994px) {
    .navbar-nav {
      text-align: center;
      margin-top: 10px;
    }
  
    .nav-item {
      margin-bottom: 10px;
    }
  
    .navbar-collapse {
      background-color: #e18d33;
      padding: 10px 0;
      border-radius: 8px;
    }
  }
  
  @media (max-width: 576px) {
    .navbar-brand {
      font-size: 1.2rem;
    }
  
    .nav-item .nav-link {
      font-size: 0.9rem;
    }
  }
  
/* RestaurantDash.css */

/* General Styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.res-dashboard {
  padding: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffb2009e;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
}

.dashboard-heading {
  margin: 0;
  font-size: 1.5rem;
}

.profile-dropdown {
  position: relative;
}

.view-profile-btn {
  background-color: white;
  color: #007bff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
}

.view-profile-btn:hover {
  background-color: #e7e7e7;
}

.dropdown-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}

.logout-btn {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  text-align: left;
}

.logout-btn:hover {
  background-color: #c82333;
}

/* Orders Table */
.orders-table-container {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.errorMessage {
  color: red;
  font-weight: bold;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.table-container th {
  background-color: #007bff;
  color: white;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-container tbody tr:hover {
  background-color: #ddd;
}

.table-container select {
  padding: 5px;
  border-radius: 4px;
}

.table-container button {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.table-container button:hover {
  background-color: #218838;
}

/* Status Table */
.status-table {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.status-table table {
  width: 100%;
  border-collapse: collapse;
}

.status-table th,
.status-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.status-table th {
  background-color: #007bff;
  color: white;
}

/* Registration Container */
.res-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.food-registration-box,
.register-food-box {
  flex: 1;
  min-width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.food-registration-box h2,
.register-food-box h3 {
  color: #007bff;
}

.register-food-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.register-food-btn:hover {
  background-color: #0056b3;
}

.about-section {
  flex: 1 100%;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 80px;
}

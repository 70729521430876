/* Center the Card on Screen */
.card {
  background: #ffffff; /* White card background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  width: 100%;
  max-width: 400px; /* Responsive width */
  padding: 20px;
  text-align: center;
  animation: fadeIn 0.5s ease; /* Smooth fade-in animation */
}

.cardrpp {
    position: absolute;
    top: 10%;
    background: #ffffff; /* White card background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%;
    max-width: 400px; /* Responsive width */
    padding: 20px;
    text-align: center;
    animation: fadeIn 0.5s ease; /* Smooth fade-in animation */
}

/* Form Styling */
form {
  width: 100%;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: inline-block;
  color: #333;
}

input.form-control {
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 15px;
  transition: border-color 0.3s ease;
}

input.form-control:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Input Group for Password with Eye Button */
.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.input-group input {
  padding-right: 40px; /* Space for the eye icon */
}

.input-group-text {
    position: absolute;
    right: 10px;
    top: 38%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff; /* Icon color */
    font-size: 1.2rem;
    z-index: 5;
}

.input-group-text:hover {
  color: #0056b3; /* Darker shade on hover */
}

/* Buttons */
button.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button.btn-primary:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

button.btn-link {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  padding: 10;
  font-size: 0.9rem;
}

button.btn-link:hover {
  color: #0056b3;
}

/* Alert Message */
.alert {
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}

/* Forgot Password Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content h3 {
  margin-bottom: 20px;
  color: #333;
}

.modal-content input {
  margin-bottom: 15px;
}

.modal-content button {
  margin: 5px;
}


body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-color: rgb(217, 223, 228); /* Subtle background for better visibility */
  font-family: Arial, sans-serif; /* Default font for readability */
}

.container-fluid {
  background-color: #e18d33; /* Navbar color */
  position: sticky; /* Ensures it stays at the top when scrolling */
  top: 0;
  padding: 10px 0; /* Adds vertical padding */
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.home-container {
  background-color: #43444d; /* Darker section background */
  height: 35vh;
  display: flex;
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
  color: aliceblue;
  text-align: center;
}

.header-section {
  text-align: center;
  color: aliceblue;
  margin: 0; /* Removes extra spacing */
  font-size: 1.5rem; /* Adjust font size for better visibility */
}

.main-section {
  text-align: center;
  color: rgb(168, 200, 227);
  padding: 20px;
  font-size: 1rem;
}

.main {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    position: relative;
    align-content: flex-start;
    flex-wrap: wrap;
}

.footer {
  /* position: relative;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); 
  width: 100%;
  margin-top: auto; */
  background-color: #333;
  bottom: 0;
  box-shadow: 0 -2px 4px #0000001a;
  color: #fff;
  left: 0;
  padding: 10px 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 1060;
}

.footer a {
  color: #e18d33; /* Link color matching the theme */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .home-container {
    height: 25vh; /* Adjust height for smaller screens */
  }

  .header-section {
    font-size: 1.2rem; /* Smaller font for mobile */
  }

  .main-section {
    font-size: 0.9rem;
    padding: 15px; /* Adjust padding */
  }
}

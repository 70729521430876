.outer-container {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    height: 100vh; /* Make the outer container take the full viewport height */
}

.inner-container {
    width: 90%; /* Example width */
    margin-top: 50px;
    margin-bottom: auto;
}

.button-container {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    margin-bottom: 20px;
}

.button-container button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    background-color: #E18D33;
    color: black; /* White text */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    margin: 0 10px; /* Space between buttons */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.button-container button:hover {
    color: #0056b3;
}

.admin-navbar {
  background:#e18d33;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    top:0;
    height: 100px;
    
  }
  
  .admin-navbar .logo {
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
  }
  
  .profile-dropdown .dropdown-toggle {
    background:#362614;
    padding: 0;
    color:azure;
    width: 60px;
  }
  
  .profile-icon {
    cursor: pointer;
    color:"black";
  }
  
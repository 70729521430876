/* RestaurantListPage.css */

/* General reset for consistent styling */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

h1 {
  text-align: center;
  color: #444;
  margin-top: 20px;
}

/* Main container for the list */
.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling the list */
.food-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.food-item {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fefefe;
}

.food-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Styling for food details */
.food-item h3 {
  margin: 0;
  color: #007bff;
  font-size: 1.2em;
}

.food-item p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #555;
}

/* Highlight for in-stock items */
.food-item p.in-stock {
  color: #28a745;
  font-weight: bold;
}

.food-item p.out-of-stock {
  color: #dc3545;
  font-weight: bold;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .container {
    padding: 15px;
  }

  .food-item {
    padding: 10px;
  }

  .food-item h3 {
    font-size: 1em;
  }
}

/* General Reset */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    color: #333;
  }
  
  /* Header */
  .forget-header {
    background-color: #007bff;
    color: white;
    padding: 1.5rem 0;
    text-align: center;
  }
  
  .forget-header h1 {
    margin: 0;
    font-size: 2rem;
  }
  
  /* Footer */
  .forget-footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 1rem 0;
    margin-top: 2rem;
  }
  
  .forget-footer p {
    margin: 0;
  }
  
  /* Main Content */
  .container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1rem;
  }
  
  .card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card h2 {
    margin-bottom: 1.5rem;
  }
  
  .card p {
    margin-bottom: 1rem;
    color: #666;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .btn-secondary {
    background-color: #6c757d;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  
  .alert {
    padding: 0.75rem 1rem;
    margin-top: 1rem;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    border-radius: 4px;
  }
  
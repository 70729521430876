/* General Styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
    box-sizing: border-box;
  }
  
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
  }
  
  /* Header Section */
  .header-section {
    width: 100%;
    background-color: #3c423a;
    color: #ffffff;
    text-align: center;
    padding: 30px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .header-section h2 {
    margin: 0;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .header-section p {
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  /* Flexbox Section */
  .flexbox-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffe8e6;
    padding: 40px 20px;
  }
  
  .flexbox-container {
    display: flex;
    gap: 20px;
    max-width: 1200px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .flexbox-item {
    flex: 1 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-size: 1.2rem;
    font-weight: bold;
    color: #ff6f61;
  }
  
  .flexbox-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Main Section */
  .main-section {
    flex: 1;
    padding: 40px 20px;
    text-align: center;
    background-color: #fdfdfd;
    width: 100%;
  }
  
  .main-section h2 {
    margin-top: 0;
    font-size: 2rem;
    color: #333333;
    font-weight: bold;
  }
  
  .main-section p {
    margin-top: 15px;
    font-size: 1.1rem;
    color: #666666;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Footer Section */
  .footer-section {
    width: 100%;
    text-align: center;
    background-color: #333333;
    color: #ffffff;
    padding: 15px 20px;
    font-size: 0.9rem;
  }
  
  .footer-section p {
    margin: 0;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 992px) {
    .flexbox-item {
      flex: 1 1 100%;
      max-width: 100%;
    }
  
    .header-section h2 {
      font-size: 2rem;
    }
  
    .main-section h2 {
      font-size: 1.8rem;
    }
  
    .main-section p {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .header-section {
      padding: 20px;
    }
  
    .header-section h2 {
      font-size: 1.8rem;
    }
  
    .header-section p {
      font-size: 1rem;
    }
  
    .main-section {
      padding: 20px 10px;
    }
  
    .main-section h2 {
      font-size: 1.6rem;
    }
  
    .main-section p {
      font-size: 0.9rem;
    }
  }
  
/* Support container styles */
.support-container {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    height: 100vh; /* Full viewport height */
    /*background-color: #f5f5f5;*/ /* Light gray background */
    padding: 20px; /* Add some padding */
}

/* Support form styles */
.support-form {
    width: 100%;
    max-width: 500px; /* Maximum width for the form */
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #ffffff; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

    /* Form heading styles */
    .support-form h2 {
        font-size: 1.8rem;
        margin-bottom: 20px;
        color: #333; /* Dark gray color for the heading */
        text-align: center;
    }

/* Form group styles */
.form-group {
    margin-bottom: 15px;
}

    .form-group label {
        display: block;
        font-size: 1rem;
        margin-bottom: 5px;
        color: #555; /* Medium gray color for labels */
    }

    .form-group input,
    .form-group textarea {
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ccc; /* Light gray border */
        border-radius: 4px; /* Rounded corners */
        outline: none; /* Remove default outline */
    }

        .form-group input:focus,
        .form-group textarea:focus {
            border-color: #007bff; /* Blue border on focus */
        }

/* Submit button styles */
.submit-button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #fff; /* White text */
    background-color: #007bff; /* Blue background */
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
}

    .submit-button:hover {
        background-color: #0056b3; /* Darker blue on hover */
    }

.admin-dashboard {
    font-family: Arial, sans-serif;
    padding: 10px;
    background-color: #d1c5ac;
    min-height: 100vh;
    margin-top: 0;
    padding-top: 20px;
  }
  
  .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d6d6d0;
    padding: 15px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .profile-dropdown {
    position: relative;
  }
  
  .dashboard-heading {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }
  
  .view-profile-btn {
    background-color: #007bff;
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-profile-btn:hover {
    background-color: #0056b3;
  }
  
  /* New Flexbox Layout */
  .dashboard-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 20px;
    padding-top: 20px;
  }
  

  /* Ensure no gap between sections */
.admin-navbar + .admin-dashboard {
  margin-top: 0;
  padding-top: 0; /* Ensure alignment */
}
  
.admin-dashboard + .footer {
  margin-top: auto;
}
.about-section {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #555;
  font-size: 16px;
  margin-bottom: 20px; /* Ensure spacing before footer */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

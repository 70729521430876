.card {
  width: 50%;
  margin: auto;
  margin-top: 50px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
}

.card h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.card form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-label {
  font-weight: bold;
  color: #555555;
}

.form-control {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.input-group-text {
  background-color: transparent;
  border: none;
}

.btn {
  padding: 10px 15px;
  border-radius: 5px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-link {
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

.alert {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}

.alert-info {
  background-color: #e7f3fe;
  color: #0c5460;
  border: 1px solid #bee5eb;
}

.invalid-feedback {
  color: red;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .card {
    width: 90%;
  }
}

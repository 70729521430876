/* Outer container styles */
.outer-container {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    height: 100vh; /* Full viewport height */
    
}

/* Inner container styles */
.inner-container {
    width: 90%; /* Example width */
    margin-top: 50px;
    margin-bottom: auto;
}

    